import React from 'react';
import { Row } from 'antd';
import PrintTemplatesDetails from './PrintTemplatesDetails';
import PrintTemplatesList from './PrintTemplatesList';

const PrintTemplatesMain = () => (
    <Row gutter={[12, 0]} className="h-full" style={{ maxHeight: 380 }}>
        <PrintTemplatesList />
        <PrintTemplatesDetails />
    </Row>
);

export default PrintTemplatesMain;
