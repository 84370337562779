import React, { useContext, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Form, Input, Select, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { PrintTemplatesMenuContext } from '../../../contexts/app/PrintTemplatesMenuContext';
import { requestError } from '../../../helpers/notification';
import PrintTemplateService from '../../../services/print-template.service';

const PrintTemplateEditionElementsForm = ({ printTemplate, type, placement }) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const { setModalContent, selectedPrintTemplate, setSelectedPrintTemplate, refreshPrintTemplate } = useContext(PrintTemplatesMenuContext);

    const encodeImage = (elements) =>
    new Promise((resolve) => {
        if (elements.type === 'IMAGE') {
                let baseURL = '';
                const reader = new FileReader();
                reader.readAsDataURL(elements.value.file);
                reader.onload = () => {
                    baseURL = reader.result;
                    resolve({ ...elements, value: baseURL });
                };
            } else {
                resolve(elements);
            }
        });

    const updatePrintTemplate = (values) => {
        if (type === 'IMAGE'){
            if (values.value.file && values.value.file.size > 200000) {
                form.setFields([
                    {
                        name: 'value',
                        errors: ['La taille du fichier ne peut pas être supérieur à 200 Ko'],
                    },
                ]);
                return;
            }
        }
        setLoading(true);
        const elements = values;
        elements.type = type;
        elements.container = placement;
        encodeImage(elements).then((updatedElements) => {
            PrintTemplateService.updateTemplateElements(printTemplate.id, [updatedElements])
                .then((updatedPrintTemplate) => {
                    setSelectedPrintTemplate(updatedPrintTemplate);
                    refreshPrintTemplate('edit', updatedPrintTemplate)
                    setModalContent(null);
                })
                .catch((error) => {
                    setLoading(false);
                    requestError(error, "Erreur lors de la modification du gabarit d'impression");
                });
            setLoading(false);
        });
    };

    const submitForm = (values) => {
        if (loading) {
            return;
        }

        updatePrintTemplate(values);
    };
    let typeInput;
    switch (type) {
        case 'IMAGE':
            typeInput = (
                <Form.Item name="value" label="Valeur">
                    <Upload listType="picture" accept="image/png, image/jpeg" maxCount={1} action="" beforeUpload={() => false}>
                        <Button icon={<UploadOutlined />}>Choisir un fichier</Button>
                    </Upload>
                </Form.Item>
            );
            break;
        case 'DATE':
        case 'PAGE':
        case 'PROJECT_NAME':
            typeInput = null;
            break;
        case 'TEXT':
            typeInput = (
                <Form.Item name="value" label="Valeur">
                    <Input type="text" placeholder="Valeur" />
                </Form.Item>
            );
            break;
        default:
            typeInput = (
                <Form.Item name="value" label="Valeur">
                    <Input type="text" placeholder="Valeur" />
                </Form.Item>
            );
    }

    let initialValues;
    switch (placement) {
        case 'HEADER_LEFT':
            initialValues = selectedPrintTemplate.headerLeft ?? {
                alignment: 'LEFT',
                position: 'TOP',
            };
            break;
        case 'HEADER_CENTER':
            initialValues = selectedPrintTemplate.headerCenter ?? {
                alignment: 'CENTER',
                position: 'TOP',
            };
            break;
        case 'HEADER_RIGHT':
            initialValues = selectedPrintTemplate.headerRight ?? {
                alignment: 'RIGHT',
                position: 'TOP',
            };
            break;
        case 'FOOTER_LEFT':
            initialValues = selectedPrintTemplate.footerLeft ?? {
                alignment: 'LEFT',
                position: 'BOTTOM',
            };
            break;
        case 'FOOTER_CENTER':
            initialValues = selectedPrintTemplate.footerCenter ?? {
                alignment: 'CENTER',
                position: 'BOTTOM',
            };
            break;
        case 'FOOTER_RIGHT':
            initialValues = selectedPrintTemplate.footerRight ?? {
                alignment: 'RIGHT',
                position: 'BOTTOM',
            };
            break;
        default:
            initialValues = null;
    }

    return (
        <Form form={form} layout="vertical" onFinish={submitForm} initialValues={initialValues || null}>
            <Form.Item name="alignment" label="Alignement">
                <Select>
                    <Select.Option value="LEFT">Gauche</Select.Option>
                    <Select.Option value="CENTER">Centre</Select.Option>
                    <Select.Option value="RIGHT">Droite</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="position" label="Position">
                <Select>
                    <Select.Option value="BOTTOM">Bas</Select.Option>
                    <Select.Option value="CENTER">Centre</Select.Option>
                    <Select.Option value="TOP">Haut</Select.Option>
                </Select>
            </Form.Item>
            {typeInput}
            <Form.Item className="mb-0">
                <Button htmlType="button" className="mr-2" onClick={() => setModalContent(null)}>
                    Annuler
                </Button>
                <Button type="primary" htmlType="submit">
                    Modifier
                </Button>
            </Form.Item>
        </Form>
    );
};

PrintTemplateEditionElementsForm.propTypes = {
    printTemplate: PropTypes.object,
    type: PropTypes.string,
    placement: PropTypes.string,
};

PrintTemplateEditionElementsForm.defaultProps = {
    printTemplate: null,
    type: null,
    placement: null,
};

export default PrintTemplateEditionElementsForm;
