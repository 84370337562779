import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { Button, Col, Form, Row } from 'antd';
import { PrintTemplatesMenuContext } from '../../../contexts/app/PrintTemplatesMenuContext';
import { requestError } from '../../../helpers/notification';
import PrintTemplateService from '../../../services/print-template.service';

const PrintTemplateDeleteForm = ({ printTemplate }) => {
    const [loading, setLoading] = useState(false);
    const { setModalContent, refreshPrintTemplate } = useContext(PrintTemplatesMenuContext);
    const { t } = useTranslation();

    const submitForm = () => {
        setLoading(true);
        PrintTemplateService.deleteTemplate(printTemplate.id)
            .then(() => {
                setLoading(false);
                refreshPrintTemplate('delete', printTemplate);
                setModalContent(null);
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('error_message.error_while_deleting_the_print_template'));
            });
    };

    return (
        <Form name="delete-print-template" onFinish={submitForm}>
            <p>
                {t('error_message.are_you_sure_you_want_to_delete_the_template')} <b>{printTemplate ? printTemplate.name : ''}</b> ?
            </p>

            <Row>
                <Col span={24} className="text-right">
                    <Button htmlType="button" className="mr-2" onClick={() => setModalContent(null)}>
                        {t('general.cancel')}
                    </Button>
                    <Button loading={loading} type="primary" htmlType="submit">
                        {t('general.confirm')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

PrintTemplateDeleteForm.propTypes = {
    printTemplate: PropTypes.object,
};

PrintTemplateDeleteForm.defaultProps = {
    printTemplate: null,
};

export default PrintTemplateDeleteForm;
