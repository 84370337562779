// import { zoomGanttToFit } from '../../helpers/zoom';
const apiUrl = window._env_.REACT_APP_PRINT_URL;

const templates = ['leftside_text', 'rightside_text', 'task_text', 'progress_text', 'task_class'];
let original;

const xdr = (url, pack, cb) => {
    if (window.ganttInstance.env.isIE) {
        window.ganttInstance.env.isIE = false;
        window.ganttInstance.ajax.post(url, pack, cb);
        window.ganttInstance.env.isIE = true;
    } else {
        // window.ganttInstance.ajax.post(url, pack, cb);
        fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: pack,
            responseType: 'arraybuffer',
          })
            .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.arrayBuffer();
            })
            .then(data => {
              cb(data);
            })
            .catch(error => {
              console.error('Error fetching the PDF:', error);
            });
    }
};

const defaults = (obj, std) => {
    const object = obj;
    // eslint-disable-next-line no-restricted-syntax
    Object.keys(std).forEach((key) => {
        if (!object[key]) {
            object[key] = std[key];
        }
    });

    return object;
};

const exportSerialize = () => {
    window.ganttInstance.exportMode = true;

    const xmlFormat = window.ganttInstance.templates.xml_format;
    const formatDate = window.ganttInstance.templates.format_date;

    // use configuration date format for serialization so date could be parsed on the export
    // required when custom format date function is defined
    window.ganttInstance.templates.xml_format = window.ganttInstance.date.date_to_str(
        window.ganttInstance.config.date_format || window.ganttInstance.config.xml_date
    );
    window.ganttInstance.templates.format_date = window.ganttInstance.date.date_to_str(
        window.ganttInstance.config.date_format || window.ganttInstance.config.xml_date
    );

    const data = window.ganttInstance.serialize();

    window.ganttInstance.templates.xml_format = xmlFormat;
    window.ganttInstance.templates.format_date = formatDate;
    window.ganttInstance.exportMode = false;

    return data;
};

const copyObjectBase = (obj) => {
    const copy = {};
    Object.keys(obj).forEach((key) => {
        if (key.charAt(0) === '$') {
            copy[key] = obj[key];
        }
    });

    const formatDate = window.ganttInstance.templates.xml_format || window.ganttInstance.templates.format_date;

    copy.start_date = formatDate(copy.start_date);
    if (copy.end_date) {
        copy.end_date = formatDate(copy.end_date);
    }

    return copy;
};

const copyObjectColumns = (obj, copy) => {
    const objectColumns = copy;
    for (let i = 0; i < window.ganttInstance.config.columns.length; i += 1) {
        const getColumnTemplate = window.ganttInstance.config.columns[i].template;
        if (getColumnTemplate) {
            let val = getColumnTemplate(obj);
            if (val instanceof Date) {
                val = window.ganttInstance.templates.date_grid(val, obj);
            }

            objectColumns[`_${i}`] = val;
        }
    }
    return copy;
};

const copyObjectAll = (obj) => {
    const copy = copyObjectBase(obj);

    // serialize all text templates
    for (let i = 0; i < templates.length; i += 1) {
        const template = window.ganttInstance.templates[templates[i]];
        if (template) {
            copy[`$${i}`] = template(obj.start_date, obj.end_date, obj);
        }
    }

    copyObjectColumns(obj, copy);
    copy.open = obj.$open;
    return copy;
};

const fixColumns = (gantt, columns) => {
    const ganttColumns = columns;
    for (let i = 0; i < columns.length; i += 1) {
        ganttColumns[i].label = columns[i].label || gantt.locale.labels[`column_${columns[i].name}`];
        if (typeof columns[i].width === 'string') {
            ganttColumns[i].width = columns[i].width;
        }
    }
};

const markColumns = (base) => {
    const { columns } = base.columns_to_keep;
    if (columns)
        for (let i = 0; i < columns.length; i += 1) {
            if (columns[i].template) columns[i].$template = true;
        }
};

// eslint-disable-next-line import/prefer-default-export
export const initPrint = () => {
    // eslint-disable-next-line no-underscore-dangle
    original = window.ganttInstance.json._copyObject;

    window.ganttInstance.serializeHtml = async () => {
        const smartScales = window.ganttInstance.config.smart_scales;
        const smartRendering = window.ganttInstance.config.smart_rendering;
        if (smartScales || smartRendering) {
            window.ganttInstance.config.smart_rendering = false;
            window.ganttInstance.config.smart_scales = false;
            window.ganttInstance.render();
        }
        setTimeout(() => {
            window.ganttInstance.scrollTo(1,1);
        }, 500);
        return new Promise((resolve) => setTimeout(() => {
            const html = window.ganttInstance.$container.parentNode.innerHTML;
            resolve(html);
        }, 2000));

        // if (smartScales || smartRendering) {
        //     window.ganttInstance.config.smart_scales = smartScales;
        //     window.ganttInstance.config.smart_rendering = smartRendering;
        //     window.ganttInstance.render();
        // }

    };

    window.ganttInstance.serializeAll = () => {
        window.ganttInstance.json.copyObject = copyObjectAll;
        const data = exportSerialize();
        window.ganttInstance.json.copyObject = original;

        return data;
    };

    window.ganttInstance.exportToPDF = async (config) => {
        let configuration = config;
        if (configuration && configuration.raw) {
            configuration = defaults(configuration, {
                name: 'gantt.pdf',
                data: await window.ganttInstance.serializeHtml(),
            });
        } else {
            configuration = defaults(configuration || {}, {
                name: 'gantt.pdf',
                data: window.ganttInstance.serializeAll(),
                config: window.ganttInstance.config,
            });
            fixColumns(window.ganttInstance, configuration.columns_to_keep);
        }

        configuration.version = window.ganttInstance.version;
        window.ganttInstance.sendToExport(configuration, 'pdf');
    };

    window.ganttInstance.exportToType = async (config, type) => {
        let configuration = config;
        if (configuration && configuration.raw) {
            configuration = defaults(configuration, {
                name: 'gantt.pdf',
                data: await window.ganttInstance.serializeHtml(),
            });
        } else {
            configuration = defaults(configuration || {}, {
                name: 'gantt.pdf',
                data: window.ganttInstance.serializeAll(),
                config: window.ganttInstance.config,
            });
            fixColumns(window.ganttInstance, configuration.columns_to_keep);
        }

        configuration.version = window.ganttInstance.version;
        window.ganttInstance.sendToExport(configuration, type);
    };

    const getExportBaseUrl = (url) => {
        const index = url.indexOf("/", url.indexOf("//") + 2); // Find the position of the first "/" after "//"
        let baseUrl = '';
        if (index !== -1) {
            baseUrl = url.substring(0, index + 1); // Extract the base URL
            console.log(baseUrl);
        } else {
            console.log('Invalid URL format');
        }
        return baseUrl;
    };

    window.ganttInstance.exportHistogram = (config, type) => {
        const server = getExportBaseUrl(apiUrl).concat('histogram');
        window.ganttInstance.ajaxToExportBuffer({...config, server}, type, config.callback);
    };

    window.ganttInstance.createHiddenForm = () => {
        if (!window.ganttInstance.hiddenExportForm) {
            const t = document.createElement('div');
            t.style.display = 'none';
            t.innerHTML =
                "<form method='POST'><textarea name='data' style='width:0px; height:0px;' readonly='true'></textarea><input type='hidden' name='type' value=''></form>";
            document.body.appendsendToExportChild(t);
            window.ganttInstance.hiddenExportForm = t;
        }
        return window.ganttInstance.hiddenExportForm;
    };

    window.ganttInstance.ajaxToExport = (data, type, callback) => {
        // eslint-disable-next-line no-param-reassign
        delete data.callback;

        const url = data.server || apiUrl;
        const pack = `type=${type}&store=1&data=${encodeURIComponent(JSON.stringify(data))}`;

        const cb = (loader) => {
            const xdoc = loader.xmlDoc || loader;
            const fail = xdoc.status > 400;
            let info = null;

            if (!fail) {
                try {
                    info = JSON.parse(xdoc.responseText);
                } catch (e) {
                    console.error(e);
                }
            }
            callback(info);
        };

        xdr(url, pack, cb);
    };

    window.ganttInstance.ajaxToExportBuffer = (data, type, callback) => {
        // eslint-disable-next-line no-param-reassign
        delete data.callback;

        const url = data.server || apiUrl;
        const pack = `type=${type}&data=${encodeURIComponent(JSON.stringify(data))}`;

        const cb = (buffer) => {
            // console.log("🚀 ~ file: export-dhtmlx.js:256 ~ cb ~ loader:", loader)
            // const xdoc = loader.xmlDoc || loader;
            // const fail = xdoc.status > 400;
            // const buffer = xdoc.responseText;

            // if (!fail) {
                callback(buffer);
            // }
        };

        xdr(url, pack, cb);
    };

    // eslint-disable-next-line consistent-return
    window.ganttInstance.sendToExport = (data, type) => {
        const exportData = data;
        /* const idsColumns = exportData.columns_to_keep.map((column) => column.id);
        exportData.config.columns = window.ganttInstance.config.columns.filter((column) =>
            idsColumns.includes(column.id)
        ); */
        const convertToGanttDateFormat = window.ganttInstance.date.date_to_str(
            window.ganttInstance.config.date_format || window.ganttInstance.config.xml_date
        );
        if (exportData.config) {
            exportData.config = window.ganttInstance.copy(exportData.config);
            markColumns(exportData, type);

            if (exportData.config.start_date && exportData.config.end_date) {
                if (exportData.config.start_date instanceof Date) {
                    exportData.config.start_date = convertToGanttDateFormat(exportData.config.start_date);
                }
                if (exportData.config.end_date instanceof Date) {
                    exportData.config.end_date = convertToGanttDateFormat(exportData.config.end_date);
                }
            }
        }

        if (exportData.callback) {
            return window.ganttInstance.ajaxToExportBuffer(exportData, type, exportData.callback);
        }

        // const form = window.ganttInstance.createHiddenForm();
        // form.firstChild.action = exportData.server || apiUrl;
        // form.firstChild.childNodes[0].value = JSON.stringify(exportData);
        // form.firstChild.childNodes[1].value = type;
        // form.firstChild.submit();
        // zoomGanttToFit();
        window.ganttInstance.config.columns = exportData.initial_columns;
    };
};
