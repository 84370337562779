const customGanttCss = `
.recharts-curve.recharts-line-curve {
    display: none;
}
.gantt_task_line.gantt_project{background-color: #fbbe3f; border-color: #fff3a1;}
.gantt_task_line.gantt_milestone {
    background-color: #2a2f35;
    border: 0;
}
.gantt_task_content {
    text-align: left;
}
.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.gantt_task_line{background-color: #828282; border-color: #e5e7eb;}
body, html {font-family: sans-serif;}
.gantt_grid_scale{background-color: #2a2f35;}
.gantt_task_line.box-shadow {
    box-shadow: 0 0 0 1px black !important;
}
.gantt_project .gantt_link_control {
    display: none !important;
}
.gantt_project .gantt_side_content.gantt_right {
    padding-left: 0 !important;
}
.gantt_project .gantt_side_content.gantt_left {
    padding-right: 0 !important;
}
.gantt_task_line.gantt_split_parent {
    display: none;
}
.gantt_task_line.gantt_split_parent .gantt_side_content span {
    display: none;
}
.gantt_task_line.gantt_split_parent .gantt_task_content span {
    display: none;
}
.gantt_task_line.gantt_split_subproject.is_split_task {
    display: none;
}
.gantt_task_line.gantt_split_child {
    opacity: 0.5;
}
.gantt_task_line.gantt_split_child .gantt_side_content span {
    display: none;
}
.gantt_task_line.gantt_split_child .gantt_task_content span {
    display: none;
}
.freeSlack {
    position: absolute;
    border-radius: 0;
    opacity: 0.7;

    border: none;
    border-right: 1px solid #b6b6b6;
    margin-left: -2px;
    background: #b6b6b6;
    background: repeating-linear-gradient(45deg, #FFFFFF,
    #FFFFFF 5px,
    #b6b6b6 5px,
    #b6b6b6 10px);
}
.totalSlack {
    position: absolute;
    border-radius: 0;
    opacity: 0.7;

    border: none;
    border-right: 1px solid #b6b6b6;
    margin-left: -2px;
    background: #b6b6b6;
    background: repeating-linear-gradient(
            -45deg, #FFFFFF,
            #FFFFFF 5px,
            #b6b6b6 5px,
            #b6b6b6 10px
    );
}
.tasklayer-container {
    position: absolute;
    border-radius: 0;
}
.gantt_cell_tree {
    display: flex;
    padding-left: 0px !important;
    flex-wrap: nowrap;
}
.gantt_grid_data .gantt_cell {
   color: inherit !important;
}
.gantt_grid_scale {
    color: #2a2f35;
    background-color: #f8f8f8;
}

.gantt_grid_scale .gantt_grid_head_cell {
    font-weight: bold;
    opacity: 1;
    color: #2a2f35;
    background-color: #f8f8f8;
    border-bottom: solid 1px #828282;
    border-right: solid 1px #828282;
}


.gantt_grid_scale > .gantt_grid_head_add {
    background-image: none;
    pointer-events: none;
}

.gantt_task_line {
    &.gantt_milestone {
        background-color: #2a2f35;
        border: 0;
    }

    &.no_link {
        &.gantt_milestone {
            background-color: #fbbe3f;
        }

        .gantt_link_control {
            display: none;
        }
    }
}
.gantt_row.gantt_row_project {
    font-weight: bold;
    background-color: #f8f8f8;
}
.gantt_task_cell.no_working_day {
    background-color: rgba(130, 130, 130, 0.3);
}
.gantt_task_progress {
    line-height: inherit;
    overflow: hidden;
    height: 100%;
    text-align: center;
    z-index: 0;
    background: black;
    opacity: 0.15;
}
.gantt_marker_content {
    color: inherit;
}
.hidden {
   display:none
}
.gantt_task .gantt_task_scale .gantt_scale_cell.day_scale {
    background-color: #f8f8f8;
    border-left: 1px solid #828282;
    border-bottom: 1px solid #828282;
}
.gantt_task .gantt_task_scale .gantt_scale_cell {
    color: black;
}
.gantt_task_cell {
    border-left: 1px solid #828282;
}
.gantt_side_content.gantt_link_crossing {
    top: 0px !important;
}
.gantt_side_content.gantt_link_crossing .gantt_side_text {
    background: rgba(255,255,255,0.8);
}
.gantt_side_content {
    z-index: 5;
    overflow: visible;
}
.gantt_task_progress_wrapper {
    z-index: 1;
}
.resource_task {
    background-color: #EDEDED;
    border: 1px solid #000;
}
`;

export default customGanttCss;