import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { Button, Checkbox, Form, Input } from 'antd';
import { PrintTemplatesMenuContext } from '../../../contexts/app/PrintTemplatesMenuContext';
import { requestError } from '../../../helpers/notification';
import PrintTemplateService from '../../../services/print-template.service';
import AuthService from '../../../services/auth.service';

const PrintTemplateEditionForm = ({ printTemplate }) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const { isAdmin } = AuthService.getCurrentUser();
    console.log("🚀 ~ file: PrintTemplateEditionForm.jsx:14 ~ PrintTemplateEditionForm ~ isAdmin:", isAdmin)

    const { setModalContent, setSelectedPrintTemplate, refreshPrintTemplate } = useContext(PrintTemplatesMenuContext);
    const { t } = useTranslation();

    const createPrintTemplate = (values) => {
        setLoading(true);
        PrintTemplateService.createTemplate(values.name, isAdmin ? values.personal : true)
            .then((updatedTemplate) => {
                setSelectedPrintTemplate(updatedTemplate);
                refreshPrintTemplate('new', updatedTemplate)
                setLoading(false);
                setModalContent(null);
            })
            .catch((error) => {
                setLoading(false);
                requestError(error, t('error_message.error_while_creating_the_print_template'));
            });
    };

    const updatePrintTemplate = (values) => {
        setLoading(true);
        // const nameChanged = values.name !== printTemplate.name;
        // const personalChanged = isAdmin ? values.personal !== printTemplate.personal : false;
        // if (nameChanged && !personalChanged) {
            PrintTemplateService.updateTemplate(printTemplate.id, values.name, isAdmin ? values.personal : true)
                .then((updatedPrintTemplate) => {
                    setSelectedPrintTemplate(updatedPrintTemplate);
                    refreshPrintTemplate('edit', updatedPrintTemplate)
                    setLoading(false);
                    setModalContent(null);

                })
                .catch((error) => {
                    setLoading(false);
                    requestError(error, t('error_message.error_when_modifying_the_print_template'));
                });
        // } 
    };

    const submitForm = (values) => {
        if (loading) {
            return;
        }
        if (printTemplate) {
            updatePrintTemplate(values);
        } else {
            createPrintTemplate(values);
        }
    };

    const initialValues = { name: printTemplate ? printTemplate.name : '', personal: printTemplate ? printTemplate.personal : false };

    return (
        <Form form={form} initialValues={initialValues} layout="vertical" onFinish={submitForm}>
            <Form.Item
                name="name"
                label={t('print.print_template_name')}
                rules={[{ required: true, message: t('print.name_of_the_print_template_is_required') }]}
            >
                <Input type="text" placeholder={t('print.template_name')} />
            </Form.Item>
            {!isAdmin ? (
                ''
            ) : (
                <Form.Item name="personal" valuePropName="checked">
                    <Checkbox checked>{t('print.personal')}</Checkbox>
                </Form.Item>
            )}
            <Form.Item className="mb-0">
                <Button htmlType="button" className="mr-2" onClick={() => setModalContent(null)}>
                    {t('general.cancel')}
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                    {t('general.save')}
                </Button>
            </Form.Item>
        </Form>
    );
};

PrintTemplateEditionForm.propTypes = {
    printTemplate: PropTypes.object,
};

PrintTemplateEditionForm.defaultProps = {
    printTemplate: null,
};

export default PrintTemplateEditionForm;
