import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Button, Empty } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { ReactComponent as PlusSvg } from '../../assets/icons/plus.svg';
// import LoadingSpin from '../utils/LoadingSpin';
import { ReactComponent as EditSvg } from '../../assets/icons/edit.svg';
import { ReactComponent as CloseSvg } from '../../assets/icons/close.svg';
import { PrintTemplatesMenuContext } from '../../contexts/app/PrintTemplatesMenuContext';
import PrintTemplateEditionForm from './form/PrintTemplateEditionForm';
import PrintTemplateDeleteForm from './form/PrintTemplateDeleteForm';
import PrintTemplateService from '../../services/print-template.service';
import AuthService from '../../services/auth.service';

const PanelWrapper = styled.div`
    background: #f8f8f8;
    border-radius: 4px;
    padding: 8px;
    height: calc(100% - 40px);
`;

const PanelTitle = styled.h2`
    font-family: 'Uni Sans Bold', sans-serif;
    font-size: 13px;
    line-height: 16px;
    color: #fbbe3f;
`;

const PlusIcon = styled(PlusSvg)`
    float: left;
    height: 32px;
    margin-left: 8px;
`;

const NameColumn = styled.span`
    width: 110px;
`;

const ActionsColumn = styled.span`
    width: 44px;

    div {
        width: 22px;
    }
`;

const PrintTemplatesList = () => {
    const { t } = useTranslation();
    const { printTemplates, selectedPrintTemplate, setSelectedPrintTemplate, setModalContent, refreshPrintTemplate } =
    useContext(PrintTemplatesMenuContext);
    const { isAdmin, readOnly } = AuthService.getCurrentUser();

    const addPrintTemplate = () => {
        setModalContent(<PrintTemplateEditionForm printTemplate={null} />);
    };

    const editPrintTemplate = (printTemplate) => {
        setModalContent(<PrintTemplateEditionForm printTemplate={printTemplate} />);
    };

    const deletePrintTemplate = (printTemplate) => {
        setModalContent(<PrintTemplateDeleteForm printTemplate={printTemplate} />);
    };

    const handleShowDetails = (printTemplate) => {
        // test if need to get template elements
        if (Object.keys(printTemplate).indexOf('color') === -1) {
            PrintTemplateService.showTemplate(printTemplate.id).then((updatedTemplate) => {
                setSelectedPrintTemplate(updatedTemplate);
                refreshPrintTemplate('edit', updatedTemplate);
            });
        } else {
            setSelectedPrintTemplate(printTemplate);
        }
    };

    return (
        <>
            <Col flex="204px" className="h-full">
                <div className="flex justify-between items-center" style={{ marginBottom: 12 }}>
                    <div>
                        <PanelTitle>{t('print.list_of_print_templates')}</PanelTitle>
                    </div>
                </div>
                <PanelWrapper className="max-h-full overflow-y-auto">
                    <ul className="pb-8" style={{ height: 292 }}>
                        {printTemplates && printTemplates.length ? (
                            printTemplates
                                .sort((firstElement, secondElement) => {
                                    if (firstElement.name < secondElement.name) {
                                        return -1;
                                    }

                                    if (firstElement.name > secondElement.name) {
                                        return 1;
                                    }

                                    return 0;
                                })
                                .map((template) => (
                                    <li key={`template-${template.id}`} className="p-0 mb-1">
                                        <div className="flex items-stretch">
                                        {template.personal && (<div className={`flex items-center px-1 ${
                                                selectedPrintTemplate && selectedPrintTemplate.id === template.id
                                                    ? 'bg-primary'
                                                    : 'bg-white'
                                            }`}><UserOutlined /></div>)}
                                            <NameColumn
                                                className={`flex-1 flex justify-between items-center  p-2 text-xs cursor-pointer
                                            ${
                                                selectedPrintTemplate && selectedPrintTemplate.id === template.id
                                                    ? 'bg-primary'
                                                    : 'bg-white'
                                            }`}
                                                onClick={() => handleShowDetails(template)}
                                            >
                                                {template.name}
                                            </NameColumn>
                                            {((isAdmin && !template.personal) || (template.personal)) && (
                                                <ActionsColumn className="flex items-center">
                                                    <div
                                                        className={`py-2 h-full rounded-r-sm cursor-pointer custom-field-edit
                                                    ${
                                                        selectedPrintTemplate &&
                                                        selectedPrintTemplate.id === template.id
                                                            ? 'bg-primary'
                                                            : 'bg-white'
                                                    }
                                                `}
                                                    >
                                                        <EditSvg
                                                            width="16"
                                                            height="16"
                                                            className="relative text-secondary-dark"
                                                            onClick={() => editPrintTemplate(template)}
                                                        />
                                                    </div>
                                                    <div className="py-2 text-center cursor-pointer">
                                                        <CloseSvg
                                                            width="16"
                                                            height="16"
                                                            className="mx-auto"
                                                            onClick={() => deletePrintTemplate(template)}
                                                        />
                                                    </div>
                                                </ActionsColumn>
                                            )}
                                        </div>
                                    </li>
                                ))
                        ) : (
                            <Empty description={t('print.no_template')} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                    </ul>

                    <Button
                        type="secondary"
                        className="relative block w-full flex items-center justify-center mt-3 cursor-pointer"
                        onClick={addPrintTemplate}
                        disabled={readOnly}
                    >
                        <PlusIcon className="absolute left-0 ml-3" /> {t('general.add')}
                    </Button>
                </PanelWrapper>
            </Col>
        </>
    );
};

export default PrintTemplatesList;
